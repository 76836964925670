<template>
	<div class="transmit_sub_container">
		<section class="print_hide">
			<div class="jobs_table">
				<table>
					<tr>
						<th>DATE</th>
						<th>SERVICE TYPE</th>
						<th>CARRIER</th>
						<th>BOOKED BY</th>
						<th>ITEM TYPE</th>
						<th>PICKUP ADDRESS</th>
						<th>DELIVERY ADDRESS</th>
						<th>PICKUP/GOODS DESCRIPTION</th>
						<th>STATUS</th>
					</tr>
					<tbody v-if="!loading">
						<tr v-for="(data, i) in courier" :key="i">
							<td>
								<p class="text-center">
									<span class="label">{{ data.booking_type }}</span>
								</p>
								<p class="text-center">{{ data.created_at | formatDate }}</p>
							</td>
							<td>
								<span class="label">{{ data.delivery_service }}</span>
								<p>{{ data.delivery_service }}</p>
								<p>Cut of time: {{ data.office_close_time }}</p>
							</td>
							<td>
								<p>{{ data.delivery_provider }}</p>
								AWB No:
								<div v-if="data.airway_bill" class="small_body">{{ data.airway_bill }}</div>
							</td>
							<td>
								<p>
									<b>CDBSA{{ data.booking_id }}</b>
								</p>
								<p>{{ data.requester_name }}</p>
								<p>{{ moment((data.created_at), ["DD-MM-YYYY", "DD-MM-YYYY"]).format("DD-MM-YYYY") }}</p>
								<p>{{ data.branch_name }}</p>
								<span class="priority-chip" v-if="data.has_priority == 1">Priority: Urgent</span>
								<span class="priority-chip1" v-if="data.has_emergency == 1">Emergency</span>
							</td>
							<td>
								<!-- {{data.type_of_good}} -->
								<div v-if="data.goods !== null">
									{{ data.goods }}
								</div>
								<div v-else>
									{{ data.type_of_good }}
								</div>
							</td>
							<td>
								<p>Collect from: {{ data.package_location }}</p>
								<p>{{ data.pickup_name }}</p>
								<p>{{ data.pickup_address_1 ? data.pickup_address_1 + ', ' : '' }}{{ data.pickup_address_2 ? data.pickup_address_2 + ', ' : "" }}{{ data.pickup_city ? data.pickup_city + ', ':''}}{{ data.pickup_state ? data.pickup_state + ', ' : '' }}{{ data.pickup_postal_code ? data.pickup_postal_code + ', ' : '' }}{{ data.pickup_country }}</p>
								<p v-if="data.matter_number">Matter Number: {{data.matter_number.matter_number}}</p>
								<p v-if="!data.matter_number && data.gl_code">GL Code: {{`${data.gl_code.office}-${data.gl_code.entity}-${data.gl_code.department}-${data.gl_code.section}-000`}}</p>
							</td>
							<td>
								<p>Send to:</p>
								<p>{{ data.delivery_name }}</p>
								<p>{{ data.delivery_address_1 ? data.delivery_address_1 + ', ' : '' }}{{ data.delivery_address_2 ? data.delivery_address_2 + ', ' : '' }}{{ data.delivery_city ? data.delivery_city + ', ' : '' }}{{ data.delivery_state ? data.delivery_state + ', ' : '' }}{{ data.delivery_postal_code ? data.delivery_postal_code + ', ' : '' }}{{ data.delivery_country }}</p>
							</td>
							<td>
								<p>Pickup Instruction: <span><br />{{ data.pickup_instructions || 'NA' }}</span></p>
								<p>Goods Description: <span><br />{{ data.goods_description || 'NA' }}</span></p>
							</td>
							<td>
								<div class="icons_wrapper">
									<a>
										<router-link title="Edit" :to="{ name: 'QuoteAndBook', params: { id: data.id } }">
											<i class="material-icons red" title="Edit">mode_edit</i>
										</router-link>
									</a>


									<a @click="deleteBooking(data.id)">
										<i class="material-icons red" title="Delete">delete</i>
									</a>

								</div>

							</td>
						</tr>
					</tbody>
				</table>
				<p v-if="!loading && courier.length == 0">No data found</p>
			</div>
			<loader v-if="loading" />
			<paginate v-if="pages > 0" v-show="!loading" :page-count="pages" active-class="paginate-active"
				:click-handler="fetchCouriers" prev-link-class="paginate-previous" next-link-class="paginate-next"
				:prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'" :no-li-surround="true">
			</paginate>
		</section>

	</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import moment from "moment";

export default {
	name: "Draft",
	components: {
		flatPickr
	},
	data() {
		return {

			enableTime: true,
			configDate: {
				mode: "range"
			},
			viewBoxShow: false,
			showpopUpdateStatus: false,
			showpopCommentDiary: false,
			showViewConsignment: false,
			courier: [],
			pages: 0,
			current_page: 0,
			loading: true
		};
	},
	filters: {
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD-MM-YYYY')
		},
	},
	created() {
		this.$emit("breadCrumbData", ["Draft"]);
		this.fetchCouriers();
	},
	methods: {
		fetchCouriers(page = 1) {
			this.loading = true;
			this.axios
				.get("/api/draft")
				.then(response => {
					console.log(response);
					this.courier = response.data.drafts;
					this.loading = false;
				})
				.catch(error => {
					this.loading = false;
					console.log(error);
				});
		},
		deleteBooking(id) {
			if (confirm("Are you sure you want to delete this booking?")) {
				this.axios.delete("api/courier/" + id)
					.then(response => {
						if (response.data.success) {
							this.toast.success(response.data.message);
						} else {
							this.toast.error(response.data.message);
						}
						this.fetchCouriers();
					})
					.catch(error => {
						console.log(error);
					})
			}
		}
	}
};
</script>
<style lang="scss">
$theme-color: #06A5ED;

.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 5px 0;

					b {
						font-weight: 700;
					}
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #063657;
					color: #fff;
					border-radius: 4px;
					padding: 5px;

					&.green {
						background: #46aa42;
					}
				}

				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}
						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.priority-chip {
	background-color: #F44336;
	color: white;
	padding: 5%;
	border-radius: 3px;
	font-size: 12px;
}

.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #063657;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

.view_optons_wrapper {
	width: 240px;
	height: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: -250px;
	top: 10px;
	padding: 10px;

	a.view_option_button {
		width: 100% !important;
		height: 40px !important;
		background: $theme-color;
		color: #000;
		display: block;
		margin-bottom: 10px;
		text-align: center;
		line-height: 40px;
	}

	a.view_option_button:last-child {
		background: #aaa;
		color: #000;

		margin-bottom: 0;
	}
}</style>
<style scoped>.jobs_table table tr td:nth-child(6) p:nth-child(2) {
	color: red;
}

.jobs_table table tr td:nth-child(7) p:nth-child(2) {
	color: red;
}</style>